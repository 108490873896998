@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,400i,700,700i");
*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  word-break: break-all;
}

:root{
  --title-size:1.5em;
  --heading-size:2em;
  --subheading-size:1.2em;
  --p-size:1.2em;
  --mrp-size:1.3em;
  --price-size:1.4em;
}

.subheadingS{
  font-size: var(--subheading-size);
}
.headingS{
  font-size: var(--heading-size);
}
.titleS{
  font-size: var(--title-size);
}
.mrpS{
  font-size: var(--mrp-size);
}
.priceS{
  font-size: var(--price-size);
}
.paraS{
  font-size: var(--p-size)!important;
}
/* html {
  font-size: 10px;
} */

body {
  /* color: #6e6e6e; */
  /* font-family: "Ubuntu", sans-serif; */
  /* font-size: 1.6rem; */
}

.profileul {
  list-style: none;
}

/* a,
a:link,
a:visited {
  color: #fcf764c9;
  text-decoration: none;
} */

.profilenavv {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
}

.main-nav-holder {
  transition: 0.2s;
  /* width: 100%; */
}

.main-nav {
  display: flex;
}
.main-nav > li {
  border: 0.1rem solid #e6e6e6;
  margin: 0 0.4rem 0 0;
  position: relative;
  min-width: 150px;
  font-size: 1.5em!important;
  padding: 0;

  background-color: #fff;
}
.main-nav > li > a,
.main-nav > li > a:link,
.main-nav > li > a:visited {
  display: block;
  padding: 0.8rem 1.5rem;
}
.main-nav > li > a:hover,
.main-nav > li > a:link:hover,
.main-nav > li > a:visited:hover {
  background: #000;
}
.main-nav > li.has-submenu > label {
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  position: relative;
  font-size: smaller;
}
.main-nav > li.has-submenu > label:hover {
  background: #e6e6e6;
}
.main-nav > li.close-nav {
  display: none;
}
/* .main-nav > li.active > a,
.main-nav > li.active > a:link,
.main-nav > li.active > a:visited,
.main-nav > li.active > label {
  background: #e6e6e6;
} */

.menu-caret {
  border-bottom: 0.6rem solid transparent;
  border-top: 0.6rem solid transparent;
  border-left: 0.6rem solid gray;
  display: inline-block;
  line-height: 0;
  margin: 0 -0.3rem 0 1.2rem;
  position: absolute;
  right: 1.4rem;
  top: 1.9rem;
  transform-origin: 0.3rem 50%;
  transition: 0.2s;
}

.submenu {
  display: flex;
  padding: 1rem 0 0;
  opacity: 0;
  position: absolute;
  transition: 0.2s;
  visibility: hidden;
  transform: translateY(-5rem);
  white-space: nowrap;
}
.submenu > .profLi {
  position: relative;
}
.submenu > .profLi > a,
.submenu > .profLi > a:link,
.submenu > .profLi > a:visited {
  color: gray;
  display: block;
  padding: 0.7rem 1.6rem;
}
.submenu > .profLi > a:hover {
  color: black;
}
.submenu > .profLi::after {
  content: "|";
  position: absolute;
  top: 0.7rem;
  right: -0.3rem;
}
.submenu > .profLi:last-of-type::after {
  display: none;
}

.check-toggle {
  display: none;
}
.check-toggle:checked ~ label {
  background: #e6e6e6;
}
.check-toggle:checked ~ .menu-caret {
  transform: rotate(90deg);
}
.check-toggle:checked ~ .submenu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.hamburgermenu,
.menu-overlay {
  display: none;
}



.avatar {
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: inline-block;
  height: 3.6rem;
  margin: 0 0 0 1rem;
  overflow: hidden;
  width: 3.6rem;
}
.avatar img {
  width: 100%;
}

.account-menu {
  border-top: 0.3rem solid #cccccc;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 5rem;
  transition: 0.2s;
  transform: translateY(-4rem);
  visibility: hidden;
  z-index: 1;
}
.account-menu .profLi {
  margin: 0 0 0.1rem;
}
/* .account-menu li a,
.account-menu li a:link,
.account-menu li a:visited {
  background: whitesmoke;
  display: block;
  padding: 0.5rem 1.5rem;
} */
/* .account-menu li a:hover {
  background: #e6e6e6;
} */
.account-menu::before {
  border-bottom: 0.8rem solid #cccccc;
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  content: "";
  position: absolute;
  top: -1rem;
  right: 1rem;
}

.check-toggle:checked ~ .account-menu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

@media (max-width: 700px) {
  .hamburgermenu,
.menu-overlay {
    display: block;
  }

  .menu-overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    transition: 0.2s;
    transform: translateX(30%);
    visibility: hidden;
    top: 0;
    z-index: 2;
  }

  .hamburgermenu {
    /* background: repeating-linear-gradient(0deg, transparent 0, transparent 0.4rem, #666666 0.4rem, #666666 0.7rem); */
    cursor: pointer;
    display: inline-block;
    height: 6rem;
    width: 20rem;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    border: 1px solid grey;
  }

  .main-nav-holder {
    background: white;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    width: 100%;
    z-index: 3;
  }

  .main-nav {
    bottom: 0;
    display: block;
    left: 0;
    overflow-y: scroll;
    position: absolute;
    right: -1.5rem;
    top: 0;
  }
  .main-nav > .profLi {
    border: none;
    border-bottom: 0.1rem solid #f2f2f2;
    margin: 0;
  }
  .main-nav > li.close-nav {
    display: block;
    text-align: right;
  }
  .main-nav > li.close-nav > label {
    cursor: pointer;
    display: block;
    height: 4rem;
    position: relative;
  }
  .main-nav > li.close-nav > label::before, .main-nav > li.close-nav > label::after {
    background: gray;
    content: "";
    height: 1.8rem;
    left: 50%;
    position: absolute;
    top: 1rem;
    transform: translateX(-50%);
    width: 0.2rem;
  }
  .main-nav > li.close-nav > label::before {
    transform: rotate(-45deg);
  }
  .main-nav > li.close-nav > label::after {
    transform: rotate(45deg);
  }
  .main-nav > li.close-nav > label:hover::before, .main-nav > li.close-nav > label:hover::after {
    background: #333333;
  }

  .submenu .profLi::after {
    display: none;
  }

  .check-toggle:checked ~ .main-nav-holder {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  .check-toggle:checked ~ .menu-overlay {
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
  .check-toggle:checked ~ .submenu {
    height: auto;
  }

  .submenu {
    display: block;
    height: 0;
    padding: 0 0 0 1rem;
    position: static;
  }
}

@media (max-width: 720px) {
  .filterNav{
    display: none;
  }
 
}