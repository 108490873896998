@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.font{
font-family: 'Montserrat', sans-serif;
}
Link{
  text-decoration: none;
  
}

/* .button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  border-radius: 3px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 30px;
  width: 9vw;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 2vw;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
} */
/* .img-hover:hover{
  border: 2px solid rgb(117, 117, 117);
} */
.buy-button{
  background-color: #1e3a2e;
  outline: none;
  border: none;
  transition: 0.2s;
  padding: 15px;
  width: 140px;
}

.buy-button:hover{

  background-color: #EABA80;
  color: white;
}
.psize{
  /* border-radius: 50%!important; */
 
  cursor: pointer;
  /* position: absolute;
   top: 9px;
   left: 14px; */
  /* background-color: black; */
}
/* .psize:hover {
  border:ipx solid #5adaff;
  color: #5adaff;
} */
.sizeSpan{
  display: flex!important;
  align-items: center;
  justify-content: center;
  /* display: inline-block!important; */
   width: 40px;
   height: 40px;
    border-radius: 50%;
   border: 1px solid grey!important;
   text-align: center;
    position: relative;
}
.sizeSpanD{
  border: 1px solid #5adaff!important;

}
.sizeSpan:hover{
  border: 1px solid #5adaff!important;

}
.sizeSpan:hover .psize{
color: #5adaff!important;

}
/* .color-hover:hover{
  border: 2px solid rgb(117, 117, 117);
} */
.colorSpan{
  display: inline-block;
   width: 40px;
   
  height: 40px; 
  border-radius: 50%;
 /* border: 2px solid rgb(207, 190, 190)!important;  */
 text-align: center; 
 position: relative;
}
.colorSpan:hover{
  border: 2px solid #5adaff!important;

}
.pcolor{
  width: 34px;
  height: 34px;
  border-radius:50%;
  padding:5px; 
  /* background-color: red; */
 border: 1px solid grey; 

  position: absolute;
  top: 1px;
  left: 1px;
}

.default-size{
  color: #5adaff;
 
}
.default-color{
  /* padding: 1px; */
  /* border: 4px solid grey!important; */
  border: 2px solid #5adaff!important;

}

@media screen and (max-width: 600px) {
  .nine h1 span {
  display: none!important;
  }
}
.nine h1 {
  text-align:center; font-size: clamp(1rem, 5.5vw, 2rem);  text-transform:uppercase; color:#222; letter-spacing:1px;
  font-family:"Playfair Display", serif; font-weight:400;
}
.nine h1 span {
  margin-top: 5px;
    font-size:15px; color:#444; word-spacing:1px; font-weight:normal; letter-spacing:2px;
    text-transform: uppercase; font-family:"Raleway", sans-serif; font-weight:500;

    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
}





.nine h1 span:after,.nine h1 span:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    height: 5px;
  background-color:#f8f8f8;
}
.favrate{
  top:5%;
  right: 5%;
  opacity: 0;
  transition:0.2s linear;
}
.cardd{
  margin-top: 20px!important;
  position: relative;
}
/* .cardd:hover .view{
  height: auto;
  padding: 5px 0;
} */
.cardd:hover .favrate{
  opacity: 1;
}
.img-hover{
  transition: 0.3s;
}

.cardd:hover .img-hover{
  scale: 1.1;
}
.cardd:hover .favrate{
  opacity: 1;
}
/* .view{
  cursor: pointer;
  height:0;
  bottom:0;
  transition:0.3s linear;
} */


@media screen and (min-width: 700px) {
  .product_detail_content {
    display: flex;
    /* padding: 20px 0; */
  }
  
  .product_detail_main {
    flex: 1 0 auto;
    /* // display: flex; */
    /* // align-items: center; */
    /* // justify-content: center; */
    width: 60%!important;
    height: auto;
    margin-right: 10px;
    /* background: #ccc; */
    /* background: linear-gradient(to bottom, #eeeeee 0%,#B7EAE0 100%); */
  }
  .product_detail_side {
    display: flex;
    align-items: center;
    justify-content: center;
   position: -webkit-sticky;
    position: sticky;
    top: 10px;
    width: 40%!important;
    height: 40%!important;
    /* color: #fff; */
    /* background: #30b298; */
  }
}

@media screen and (max-width: 700px) {
  .product_detail_content {
    width: 100%;
    /* display: flex; */
    /* padding: 20px 0; */
}
.product_detail_side {
width: 100%;
height: auto;
  /* color: #fff; */
  /* background: #30b298; */
}
.product_detail_content {
  /* display: flex; */
  padding: 20px 0;
}
}



.basket-module,
.basket-labels,
.basket-product {
  width: 100%;
}
.basket{
  /* width: 100%;
   */
   background: #fff;
}
/* .aside{
  
} */

input,
button,
.basket,
.basket-module,
.basket-labels,
.item,
.price,
.quantity,
.subtotal,
.basket-product,
.product-image,
.product-details {
  float: left;
}

.price:before,
/* .subtotal:before, */
/* .subtotal-value:before, */
.total-value:before,
.promo-value:before {
  content: "₹";
}

.hide {
  display: none;
}

main {
  clear: both;
  /* font-size: 0.75rem; */
  margin: 0 auto;
  overflow: hidden;
  padding: 1rem 0;
  width: 960px;
}

.basket,
aside {
  padding: 0 1rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* .basket {
  width: 70%;
} */

.basket-module {
  color: #111;
}

/* label {
  display: block;
  margin-bottom: 0.3125rem;
  font-size: 1.4em;
} */

.promo-code-field {
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-transform: uppercase;
  transition: all 0.2s linear;
  width: 48%;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.promo-code-field:hover,
.promo-code-field:focus {
  border: 1px solid #999;
}

.promo-code-cta {
  border-radius: 4px;
  /* font-size: 0.625rem; */
  margin-left: 0.625rem;
  padding: 0.6875rem 1.25rem 0.625rem;
}

.basket-labels {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 1.625rem;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cart-Listt-heading {
  color: #111;
  display: inline-block;
  padding: 0.625rem 0;
}

.cart-Listt-heading.price:before,
.cart-Listt-heading.subtotal:before {
  /* content: ""; */
}

.item {
  width: 55%;
}

.price,
.quantity,
.subtotal {
  width: 15%;
}

.subtotal {
  text-align: right;
}

.remove {
  bottom: 1.125rem;
  float: right;
  position: absolute;
  right: 0;
  text-align: right;
  width: 45%;
}

.remove button {
  background-color: transparent;
  color: #777;
  float: none;
  text-decoration: underline;
  text-transform: uppercase;
}

.item-heading {
  padding-left: 4.375rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.basket-product {
  border-bottom: 1px solid #ccc;
  padding: 1rem 0;
  position: relative;
}

.product-image {
  width: 35%;
}

.product-details {
  width: 65%;
}

.product-frame {
  border: 1px solid #aaa;
}

.product-details {
  padding: 0 1.5rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.quantity-field {
  background-color: #ccc;
  border: 1px solid #aaa;
  border-radius: 4px;
  /* font-size: 0.625rem; */
  padding: 2px;
  width: 3.75rem;
}

aside {
  background: #fff;
  float: right;
  position: relative;
  /* width: 30%; */
}

.summary {
  background-color: #eee;
  border: 1px solid #aaa;
  padding: 1rem;
  /* position: fixed; */
  width: 330px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.summary-total-items {
  color: #666;
  /* font-size: 0.875rem; */
  text-align: center;
}

.summary-subtotal,
.summary-total {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  clear: both;
  margin: 1rem 0;
  overflow: hidden;
  padding: 0.5rem 0;
}

.subtotal-title,
.subtotal-value,
.total-title,
.total-value,
.promo-title,
.promo-value {
  color: #111;
  float: left;
  width: 50%;
}

.summary-promo {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.promo-title {
  float: left;
  width: 70%;
}

.promo-value {
  color: #8b0000;
  float: left;
  text-align: right;
  width: 30%;
}

.summary-delivery {
  padding-bottom: 3rem;
}

.subtotal-value,
.total-value {
  text-align: right;
}

.total-title {
  font-weight: bold;
  text-transform: uppercase;
}

.summary-checkout {
  display: block;
}

.checkout-cta {
  display: block;
  float: none;
  /* font-size: 0.75rem; */
  text-align: center;
  text-transform: uppercase;
  padding: 0.625rem 0;
  width: 100%;
}

.summary-delivery-selection {
  background-color: #ccc;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: block;
  /* font-size: 0.625rem; */
  height: 34px;
  width: 100%;
}
