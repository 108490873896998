/* #mobileNavLinks:hover{
    background-color: #b7bdc6bb;
} */

#mobileNavLinks:hover + .submobilemenu,
.submobilemenu:hover {
    /* background-color: #76787d9b; */
    border-left: 5px solid #76787d9b;
    cursor: pointer;
    top: 0px;
    opacity: 1;
    max-height: 1000px; /* Adjust the maximum height based on your content */
    overflow: hidden;
    transition:  max-height 600ms ease-in-out;
}
/* .offcanvas_body {
    background-color: #5565839b;

} */

.submobilemenu {
    display: block;
    padding: 0px;
    /* background-color: rgb(255, 0, 34); */
    max-height: 0;
    overflow: hidden;
    transition: background-color 500ms, max-height 500ms ease-in-out;
}

#submenuLinks:hover + .childmobilemenu,
.childmobilemenu:hover {
    transition-delay: 250ms;
    cursor: pointer;
    display: block;
    max-height: 1000px; /* Adjust the maximum height based on your content */
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
}

.childmobilemenu {
    display: block;
    padding: 10px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
}

.mobilemenuul li a {
    list-style-type: none !important;
    text-decoration: none;
    color: #556583;
    padding: 20px;
    font-size: 1.2em;
}

#submenuLinks {
    text-decoration: none;
    padding: 10px;
}

.childmobilemenu li {
    list-style-type: none;
    text-decoration: none;
    color: #556583;
    padding: 10px;
}

.mobilemenuul li span a {
    text-decoration: none;
    list-style-type: none !important;
    /* color: #556583; */
    padding: 10px;
    text-transform: uppercase;
}

.mobilemenuul li {
    list-style-type: none;
    /* background-color: #b7becbda; */
    margin: 2px;
    /* padding: 10px; */
    text-transform: uppercase;
   
}
