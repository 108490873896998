/* navigation css start  */
.breadcrumbdiv {
  background-color: #f9f9f9;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Raleway", sans-serif;
}

nav {
  font-family: "ADLaM Display", cursive;
  font-family: "Dancing Script", cursive;
  font-family: "Dosis", sans-serif;
  font-family: "Montserrat", sans-serif;
  /* position: fixed; */
  position: sticky;
  z-index: 999;
  width: 100%;

  background: #fff;
}
nav .wrapper {
  position: relative;
  /* max-width: 1300px; */
  width: 100%;
  /* padding: 0px 30px; */

  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo a {
  color: #000;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.wrapper .nav-links {
  display: inline-flex;
  z-index: 2;
}
.nav-links li {
  list-style: none;
  padding-bottom: 40px;
  padding-top: 40px;
}
.nav-links li a {
  color: #000;
  text-decoration: none;
  font-size: 19px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links li a:hover {
  /* background: #3A3B3C; */
  border-bottom: #242526;
}
.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background: #fff;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 150px;
  opacity: 1;
  visibility: visible;
}

.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  /* padding: 0 30px; */
  top: 80px;
  opacity: 0;
  /* min-height: 600px; */
  visibility: hidden;
}
.mega-box .contentdrop {
  background: #fff;
  padding: 15px 20px;
  display: flex;
  width: 100%;
  min-height: 300px;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.mega-box .contentdrop .row {
  width: calc(25% - 30px);
  line-height: 45px;
}
.contentdrop .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contentdrop .row header {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}
.contentdrop .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}
.row .mega-links li {
  padding: 0 20px;
}
.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #000;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover {
  color: #000;
}
.wrapper .btn {
  color: #000;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}
.logo {
  display: inline-block;
  object-fit: contain;
  width: 30%;
}
/* .navlogo {
  width: 45%;
  margin: 5px;
} */
nav .material-symbols-outlined {
  font-size: 30px;
}
.desktop-item {
  text-transform: uppercase;
}

.cartcountdiv {
  position: relative;
  width: 30px;
  height: 12px;
  color: #fff;
}
.cartcountspan {
  position: relative;
  top: -45px;
  left: -12px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-weight: 700;
  padding: 4px;
  font-size: 12px;
}

.navcartI,
.navuserI,
.navmenuI,
.navsearchI {
  font-size: 35px !important;
  cursor: pointer !important;
}
@media screen and (min-width: 650px) and (max-width: 1350px) {
  .navlogo {
    width: 17%;
    text-align: center;
  }
  .logo {
    width: 60%;
    text-align: center;
  }
}
@media screen and (min-width: 1350px) {
  .navlogo {
    width: 45%;
    text-align: center;
  }
}
@media screen and (max-width: 650) {
  .navlogo {
    width: 25%;
    text-align: center;
  }
  .logo {
    width: 60%;
    text-align: center;
  }
}
@media screen and (max-width: 1350px) {
  .wrapper .btn {
    display: block;
    color: #000;
  }

  .breadcrumbdiv {
    display: none !important;
  }

  .navcartI,
  .navuserI,
  .navmenuI,
  .navsearchI {
    font-size: 25px !important;
    cursor: pointer !important;
  }
  .cartcountdiv {
    position: relative;
    width: 23px;
    height: 20px;
    color: #fff;
  }
  .cartcountspan {
    position: relative;
    top: -42px;
    left: -15px;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    padding: 3px;
    font-size: 12px;
  }

  .indicatenav {
    /* display: none; */
  }
  nav .material-symbols-outlined {
    font-size: 22px;
  }
  .collimg {
    display: none;
  }

  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #fff;
    color: #000;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }
  /* #menu-btn:checked ~ .nav-links{
      left: 0%;
    }
    #menu-btn:checked ~ .btn.menu-btn{
      display: none;
    }
    #close-btn:checked ~ .btn.menu-btn{
      display: block;
    } */
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }

  .contentdrop .row header {
    font-size: 19px;
  }
}
.navinput {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}
.cateview {
  min-height: 350px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("https://www.sesderma.com/on/demandware.static/-/Sites-sesderma_navigation_catalog/default/dwa67a1988/banner.jpg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  background-attachment: fixed;
  text-transform: uppercase;
}

/* search --------------------- */
.searchdiv {
  position: absolute;
}
.search-container {
  position: relative;
  height: 50px;
}

.search-input {
  background-color: #fff;
  font-size: 18px;
  height: 50px;
  /* width:100%; */
  width: 0;
  display: none;

  border: none;
  padding: 15px;
  transition: width display 0.3s ease; /* Expanding input*/
}

.search-btn {
  background-color: #fff;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 4;
  left: -45px;
  height: 50px;
  width: 50px;
  color: #000;
  transition: transform 0.3 ease; /*moving button to the rigth*/
}
.search-btn:hover {
  background-color: #fff;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 4;
  left: -45px;
  height: 50px;
  width: 50px;
  color: #000;
  transition: transform 0.3 ease; /*moving button to the rigth*/
}
.search-btn:focus,
.search-input:focus {
  outline: none;
}

.search-container.active .searchinput {
  width: 100% !important;
  display: block;
}

.searchinput {
  width: 0%;
  /* padding: 10px; */
  background-color: #fff;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: width transform 0.3 ease;
}
.searchcontainer.active .searchinput {
  width: 80% !important;
  margin-top: 10px;
  font-size: 18px;
  padding: 13px;
  display: block;
}
.searchbutton {
  display: none !important;
}
.searchcontainer.active .searchbutton {
  position: relative;
  padding: 0;
  height: 45px;
  top: 14px;
  right: 95px;
  width: 90px;
  display: block !important;
}

/* .searchcontainer.active .search-btn{
  transform: translateX(80%);
} */

.search-btn:focus,
.searchinput:focus {
  outline: none;
}
